<template>
    <user-dashboard-layout>
        <v-row v-if="widget.id" justify="center">
            <v-col cols="4">
                <v-card flat rounded="lg" class="pa-2">
                    <WidgetForm :widget="widget" />
                </v-card>
            </v-col>
            <v-col cols="8">
                <Widget
                    :preview="true"
                    :widget="widget"
                    :form="form"
                    @submit="() => {}"
                />
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <v-row class="d-flex justify-center">
                    <v-icon
                        large
                        class="spinner-icon"
                        color="blue-grey darken-2"
                    >
                        mdi-autorenew
                    </v-icon>
                </v-row>
            </v-col>
        </v-row>
    </user-dashboard-layout>
</template>

<script>
    import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
    import WidgetForm from "./partials/WidgetForm.vue";
    import Widget from "../../../components/widget/Widget.vue";
    import { mapActions, mapGetters } from "vuex";
    export default {
        name: "DashboardWidgetSettings",
        components: { UserDashboardLayout, WidgetForm, Widget },
        data: function() {
            return {
                form: {
                    name: "",
                    email: "",
                    phone: "",
                    details: "",
                    date: "",
                    date_object: "",
                    schedule_id: "",
                    schedule_object: "",
                    errors: {},
                    roundedCorners: false
                }
            }
        },
        computed: {
            ...mapGetters(["widget", "scheduleByDay"])
        },
        methods: {
            ...mapActions([
                "setWidget",
                "updateWidget",
                "storeWidget",
                "setScheduleByDay"
            ])
        },
        created() {
            this.setWidget();
        },
        watch: {

        }
    }
</script>

<style scoped>
    .v-btn {
        width: 253px;
        height: 56px;
        border-radius: 10px;
    }

    .spinner-icon {
        transform: rotate(360deg);
        animation: linear spin 5s infinite;
    }

    @keyframes spin {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
</style>
