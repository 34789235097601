<template>
    <v-expansion-panels v-model="panel" multiple flat class="icon-toggle">
        <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-plus">
                {{ $t('general.fields') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-text-field
                    v-model="widget.title"
                    outlined
                    dense
                    :label="$t('general.title')"
                />

                <v-select
                    :label="$t('general.font')"
                    :items="fonts"
                    v-model="widget.style.fontFamily"
                    item-text="font"
                    item-value="value"
                    dense
                    outlined
                ></v-select>

                <v-checkbox label="Rounded corners"
                            v-model="widget.style.roundedCorners"
                            class="mt-0"
                ></v-checkbox>
            </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel flat>
            <v-expansion-panel-header expand-icon="mdi-plus">
                {{ $t('general.color-theme') }}
            </v-expansion-panel-header>

            <v-expansion-panel-content>
                <ColorPicker :widget="widget"/>
            </v-expansion-panel-content>
        </v-expansion-panel>

        <div class="caption mx-3 max-width-100">
            <div class="d-flex justify-lg-space-between">
                <v-card-title class="mx-2 pa-0 body-2 text--disabled">{{ $t('general.code') }}</v-card-title>
                <v-btn
                    elevation="0"
                    width="100"
                    color="#ffffff"
                    class="mx-2 pa-0 black--text text-none letter-spacing-0"
                    @click="copyCodeBuffer"
                >
                    <v-icon
                        small
                        color="black"
                    >
                        mdi-content-copy
                    </v-icon>
                    {{ $t('general.copy') }}
                </v-btn>
            </div>
            <v-card elevation="0" color="#F7F7F7" class="pa-2 code-card">
                {{ embedCode() }}
            </v-card>
            <v-btn
                elevation="0"
                color="primary"
                @click="updateWidget()"
                class="my-3 widget_btn text-none letter-spacing-0"
            >
                {{ $t('general.save-changes') }}
            </v-btn>
        </div>

    </v-expansion-panels>
</template>

<script>
import ColorPicker from "./ColorPicker.vue";
import {mapActions} from "vuex";

export default {
    data() {
        return {
            panel: [0, 1, 2],
            items: 5,
            fonts: [
                {font: 'Poppins', value: "Poppins, sans-serif"},
                {font: 'Inter', value: "Inter, sans-serif"},
            ],
            roundedCorners: false
        };
    },

    props: ["widget"],
    components: {ColorPicker},
    methods: {
        all() {
            this.panel = [...Array(this.items).keys()].map((k, i) => i);
        },
        embedCode() {
            const script = document.createElement("script");
            script.setAttribute(
                "src",
                process.env.VUE_APP_APP_URL + "/js/widget.umd.js"
            );
            script.setAttribute("defer", true);
            script.setAttribute("data-token", this.widget.token);
            script.setAttribute("base-url", process.env.VUE_APP_APP_URL);
            script.setAttribute("data-widget-base-url", process.env.VUE_APP_APP_URL);
            script.setAttribute("id", "script-widget-base-url");

            let wrapper = document.createElement("div");
            let container = document.createElement("div");
            container.setAttribute("id", "widget-container");

            wrapper.appendChild(container);
            wrapper.appendChild(script);

            return wrapper.outerHTML;
        },
        copyCodeBuffer() {
            if (window.isSecureContext && navigator.clipboard) {
                navigator.clipboard.writeText(this.embedCode())
            } else {
                this.unsecuredCopyToClipboard(this.embedCode())
            }
        },
        unsecuredCopyToClipboard(text) {
            const textArea = document.createElement("textarea")
            textArea.value = text
            document.body.appendChild(textArea)
            textArea.focus()
            textArea.select()
            try {
                document.execCommand('copy')
            } catch (err) {
                console.error('Unable to copy to clipboard', err)
            }
            document.body.removeChild(textArea)
        },
        ...mapActions([
            "updateWidget"
        ])
    }
};
</script>

<style scoped>
.letter-spacing-0 {
    letter-spacing: 0
}

.max-width-100 {
    max-width: 100%;
}

.code-card {
    color: black;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

.widget_btn {
    padding: 8px 0;
    border-radius: 10px;
    font-weight: 600;
    color: #000;
    cursor: pointer;
    background: none;
    border: none;
    width: 253px !important;
    height: 56px !important;
    display: block;
    margin: 0 auto;
    font-size: 16px;
}

.widget_btn span {
    white-space: nowrap;
}

.icon-toggle {
    .v-expansion-panel-header--active .mdi-plus::before {
        content: "\F0374";
    }
}
</style>
