/**
 * Checks whether the payment method can be set mollie by default
 * @param settings
 * @return {Boolean}
 */
export function checkAutoMolliePayment(settings) {
    if (settings.pickup_mollie_enabled && !settings.pickup_cash_enabled) {
        return true
    }
    return false
}

/**
 * Checks whether the payment method can be set cash by default
 * @param settings
 * @return {Boolean}
 */
export function checkAutoCashPayment(settings) {
    if (settings.pickup_cash_enabled && !settings.pickup_mollie_enabled) {
        return true
    }
    return false
}