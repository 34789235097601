<template>
    <div>
        <div class="customer-orders__title">{{ $t('general.my-orders') }}</div>

        <table class="customer-orders-table">
            <thead>
                <tr class="customer-orders-table-thead-tr">
                    <th class="customer-orders-table-th" v-for="header in headers" :key="header.text">
                            <span class="customer-orders-table-th__title">{{
                                    $t('general.' + header.text.toLowerCase())
                                }}</span>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr class="customer-orders-table-tr" v-for="item in orders" :key="item.id">
                    <td class="customer-orders-table-td">{{ item.name }}</td>
                    <td class="customer-orders-table-td">{{ item.phone }}</td>
                    <td class="customer-orders-table-td">{{ item.email }}</td>
                    <td class="customer-orders-table-td">{{ item.details }}</td>
                    <td class="customer-orders-table-td">
                        {{ (itemStatus = getStatus(item.status), '') }}
                        <span v-if="item">
                            <span :class="itemStatus">{{ $t('general.' + itemStatus) }}</span>
                            <span v-if="isNewOrder(item)" class="warning" style="margin-left: 4px">
                                {{ $t('general.new') }}
                            </span>
                        </span>
                    </td>
                    <td class="customer-orders-table-td">
                        {{ new Date(item.date).toLocaleDateString() }}
                        {{ item.time }}
                    </td>
                    <td class="customer-orders-table-td">{{ item.created_at }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {getCustomerOrders} from "@/widget/api";

export default {
    data: () => ({
        loading: false,
        orders: [],
        headers: [
            {
                text: "Name",
                value: "name",
                sortable: true,
            },
            {
                text: "Phone",
                value: "phone"
            },
            {
                text: "Email",
                value: "email"
            },
            {
                text: "Details",
                value: "details"
            },
            {
                text: "Status",
                value: "status",
            },
            {
                text: "Delivery-date",
                value: "date"
            },
            {
                text: "Created",
                value: "created_at",
                sortable: true,
            }
        ],
    }),
    methods: {
        isNewOrder(item) {
            if (new Date(item.created_at).getDate() === new Date().getDate()) {
                return true
            }
        },
        getStatus(status) {
            status = status.trim().toLowerCase() ?? ''
            return status.length === 0 ? 'failed' : status === 'created' ? 'open' : status
        }
    },
    mounted() {
        this.loading = true
        getCustomerOrders().then((res) => {
            this.orders = res
            this.loading = false
        })
    }
}
</script>

<style scoped lang="scss">
.customer-orders {
    &__title {
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 700;
        color: #333;
    }
}

.customer-orders-table {
    width: 100%;

    &-thead-tr {
        background-color: #f0f0f0;
    }

    &-th {
        padding: 4px 8px;
        text-align: left;
        &__title {
            font-size: 12px;
            color: #333;
        }
    }

    &-tr {
    }

    &-td {
        padding: 4px 8px;
        font-size: 12px;
        color: #333;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    .created,
    .open {
        color: #4474ee;
    }

    .paid {
        color: #4caf50;
    }

    .cancelled,
    .warning {
        color: #ffc107;
    }

    .expired,
    .failed {
        color: #ff5252;
    }
}
</style>
