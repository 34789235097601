<template>
    <div>
        <template v-if="dataWidget">
            <div class="title">{{ $t('general.select-shipping') }}</div>
            <div class="datepicker" :class="widget.style.roundedCorners ? '' : 'border-radius-0'">
                <div v-for="(group, key) in dataWidget" :key="`month_${key}`" style="flex:auto">
                    <div class="month">{{ getMonthName(group) }}</div>
                    <div class="dates">
                        <div class="date" v-for="(day, i) in group" :key="i">
                            <div>{{ $t('general.' + day.day_name.toLowerCase()) }}</div>
                            <label
                                :class="[widget.style.roundedCorners ? '' : 'border-radius-0',
                                        isDisabled(day) ? 'disabled' : '',
                                        form.date_object.id === day.id ? 'selected' : '']"
                                :style="{background: form.date_object.id === day.id ? widget.style.color : ''}"
                            >
                                <input
                                    v-model="form.date_object"
                                    type="radio"
                                    hidden
                                    :disabled="isDisabled(day)"
                                    :value="day"
                                    required
                                    :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                    @change="$emit('change-date')"
                                />

                                {{ day.day }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>

export default {
    props: ["widget", "form"],
    data: () => ({
        monthCurrent: '',
        monthNext: '',
        month_list: [
            {
                nl: 'januari',
                en: 'January'
            },
            {
                nl: 'februari',
                en: 'February'
            },
            {
                nl: 'maart',
                en: 'March'
            },
            {
                nl: 'april',
                en: 'April'
            },
            {
                nl: 'mei',
                en: 'May'
            },
            {
                nl: 'juni',
                en: 'June'
            },
            {
                nl: 'juli',
                en: 'July'
            },
            {
                nl: 'augustus',
                en: 'August'
            },
            {
                nl: 'september',
                en: 'September'
            },
            {
                nl: 'oktober',
                en: 'October'
            },
            {
                nl: 'november',
                en: 'November'
            },
            {
                nl: 'december',
                en: 'December'
            }
        ]
    }),
    computed: {
        dataWidget: function () {
            if (this.widget && this.widget.data) {
                return this.widget.data
            }
            return ''
        },
        firstElementOfData: function () {
            if (this.dataWidget && this.dataWidget[0]) {
                return this.dataWidget[0]
            }
            return ''
        },
        lastElementOfData: function () {
            if (this.dataWidget && this.dataWidget[this.dataWidget.length - 1]) {
                return this.dataWidget[this.dataWidget.length - 1]
            }
            return ''
        },
        monthFirst: function () {
            if (this.firstElementOfData) {
                return this.firstElementOfData.day_month
            }
            return ''
        },
        monthSecond: function () {
            if (this.firstElementOfData && this.lastElementOfData && this.monthFirst !== this.lastElementOfData.day_month) {
                return this.lastElementOfData.day_month
            }
            return ''
        },
        searchFirstDayWithShedule: function () {
            if (this.dataWidget) {
                for (const property in this.dataWidget) {
                    let group = this.dataWidget[property];
                    let first = group.find(fn => fn.day_shedules.length > 0);
                    if (first) {
                        return first;
                    }
                }
            }
            return ''
        }
    },
    watch: {
        'form.date_object': function (val) {
            this.form.date = val.day_data
        }
    },
    methods: {
        getMonthName(group) {
            let index = 0;
            if (Array.isArray(group)) {
                index = group[0].month_index;
            }
            if (this.$i18n.locale === 'nl') {
                return this.month_list[index].nl;
            } else {
                return this.month_list[index].en
            }
        },
        isDisabled(day) {
            if (day.is_holiday) {
                return true
            }
            if (this.form.is_vending && day.vending_block) {
                return true
            }
            return false
        },
    },
    mounted() {
        this.form.date_object = this.searchFirstDayWithShedule
    },
};
</script>

<style lang="scss" scoped>
.datepicker {
    display: flex;
    overflow: auto;
    height: 114px;
    background: #ffffff;
    border: 1px solid #cecece;
    border-radius: 5px;
    padding: 8px 14px;

    .month {
        color: #232020;
        font-weight: 600;
        width: 100%;
    }

    .dates {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        margin-right: 10px;

        .date {
            display: grid;
            gap: 5px;
            justify-items: center;
            font-size: 12px;

            label {
                border-radius: 50%;
                color: #000;
                background-color: #fff;
                width: 30px;
                height: 30px;
                display: grid;
                place-items: center;
                cursor: pointer;
                margin-right: 1px;
                border: 1px solid #ddd;

                &.disabled {
                    color: #000;
                    background-color: #eee;
                    border: 1px solid #999;
                    cursor: not-allowed;
                    opacity: .4;
                }

                &.selected {
                    color: #fff;
                    cursor: default;
                }
            }
        }
    }
}

.title {
    font-family: 'Inter', sans-serif;
    line-height: 17px;
    color: #232020;
    margin-bottom: 17px;
    font-size: 1.25rem !important;
    font-weight: 500;
    letter-spacing: .0125em !important;
}

</style>
