<template>
    <div class="product-cart" v-if="!order_created">
        <h3 class="cart-title">{{ $t('general.shopping-cart') }}</h3>
        <v-list-item
            v-for="(item, index) in cart"
            :key="index"
            class="card-item mb-3 d-block"
        >
            <v-card class="card-product d-flex">
                <div class="remove-from-cart" @click="remove_from_cart(item)">&times;</div>
                <v-img
                    :width="124"
                    :height="108"
                    :max-width="124"
                    :max-height="108"
                    cover
                    :src="item.image"
                    class="card-product__img"
                    :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                ></v-img>
                <div class="card-product__data">
                    <v-card-text class="card-product__wrap-text pa-0">
                        <v-card-title class="text-h5 card-product__title text-bold pa-0"
                                      v-if="item.name.length > 60">{{ item.name.slice(0, 60) + '..' }}
                        </v-card-title>
                        <v-card-title class="text-h5 card-product__title text-bold pa-0" v-else>{{
                                item.name
                            }}
                        </v-card-title>
                    </v-card-text>
                    <div class="wrap_select">
                                        <span v-if="item.variation_name.length > 60">{{
                                                item.variation_name.slice(0, 60) + '..'
                                            }}</span>
                        <span v-else>{{ item.variation_name }}</span>
                        <input type="hidden" :value="item.variation_price" :id="'variation_price_' + item.id_product"
                               :class="widget.style.roundedCorners ? '' : 'border-radius-0'">
                    </div>
                    <div v-if="item.variation_name !== ''" class="order-block__price"
                         :style="{color: widget.style.color}">&#8364;
                        <span :id="'cart_value_price_' + item.id_product + '_' + item.variation_name"
                              :style="{color: widget.style.color}">{{ itemPrice(item) }}</span></div>
                    <div v-else class="order-block__price" :style="{color: widget.style.color}">&#8364;
                        <span :id="'cart_value_price_' + item.id_product">{{ itemPrice(item) }}</span></div>
                    <v-card-actions :class="widget.style.roundedCorners ? 'card-actions' : 'border-radius-0'"
                    class="pl-0 pb-0">
                        <div class="order-block">
                            <div class="qty-block">
                                <div v-if="item.variation_name !== ''" class="qty-block__interface">
                                    <v-btn small @click="btnMinusPopupCart(item)"
                                           :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                           class="order-block__btnMinus btn-qty">
                                        -
                                    </v-btn>
                                    <v-text-field
                                        solo small flat
                                        :id="'qty_cart_' + item.id_product + '_' + item.variation_name"
                                        class="qty-block__input order-block__text-field"
                                        :value="item.qty_product"
                                        type="number"
                                        hide-details
                                        min="0"
                                        @input="checkoutOrder(item)"
                                        :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                    />
                                    <v-btn small @click="btnPlusPopupCart(item)"
                                           :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                           class="order-block__btnPlus btn-qty">
                                        +
                                    </v-btn>
                                </div>
                                <div v-else class="qty-block__interface">
                                    <v-btn small @click="btnMinusCart(item)"
                                           :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                           class="order-block__btnMinus btn-qty">
                                        -
                                    </v-btn>
                                    <v-text-field
                                        solo small flat
                                        :id="'qty_cart' + item.id_product"
                                        class="qty-block__input order-block__text-field"
                                        :value="item.qty_product"
                                        type="number"
                                        hide-details
                                        min="0"
                                        :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                        @input="checkoutOrder(item)"
                                    />
                                    <v-btn small @click="btnPlusCart(item)"
                                           :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                           class="order-block__btnPlus btn-qty">
                                        +
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </v-card-actions>
                </div>
            </v-card>
        </v-list-item>
        <div class="total-price" v-if="deliveryCost">
            Delivery: <span class="total-price__price" :style="{color: widget.style.color}">€ {{ deliveryCost.toFixed(2) }}</span>
        </div>
        <div class="total-price" v-if="deliveryCost">
            {{ $t('general.total') }}: <span class="total-price__price" :style="{color: widget.style.color}"> € {{ total_price }}</span>
        </div>
        <div class="total-price" v-else>
            {{ $t('general.total') }}: <span class="total-price__price" :style="{color: widget.style.color}">€ {{ total_price }}</span>
        </div>
        <div class="widget_buttons mobile-hide">
            <button
                v-if="blocked_btn"
                type="button"
                class="widget_btn btn_submit"
                :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                :style="{ background: '#777', color: '#fff' }">
                    <span class="spinner-icon">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 4335 4335"
                             width="100" height="100"><path fill="#ffffff"
                                                            d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z"
                        /></svg></span>
            </button>
            <button
                v-else
                type="submit"
                class="widget_btn"
                :form="form"
                :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                :style="{ background: widget.style.color, color: '#fff' }">
                <span>{{ $t('general.submit-order') }}</span>
            </button>
            <div class="order_status_message">{{ order_status_message }}</div>
        </div>
    </div>
</template>

<script>

export default {
    props: ["widget", "order_created", "blocked_btn", "cart", "total_price", "total_price_calc", "btnPlusCart", "btnMinusCart", "btnPlusPopupCart", "btnMinusPopupCart", "deliveryCost", "order_status_message"],
    data: () => ({
        form: 'widget_form',
    }),
    methods: {
        itemPrice(item) {
            return (parseFloat(item.price) +  parseFloat(item.variation_price)) * parseInt(item.qty_product)
        },
        checkoutOrder(item) {
            if (document.getElementById(`qty_${item.id}`).value < 1)
                document.getElementById(`qty_${item.id}`).value = 0
        },
        remove_from_cart(item) {
            for (const [index, value] of this.cart.entries()) {
                if (value.id_product === item.id_product && value.variation_name === item.variation_name) {
                    this.cart.splice(index, 1);
                }
            }
            this.total_price_calc()
            this.$emit('remove-from-cart');
        },
    }
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 910px) {
    .total-price {
        margin-bottom: 31px;
        margin-left: 0;
    }
    .product-cart .cart-title {
        padding-left: 0;
    }
    .order_details .product-cart .cart-title {
        padding-left: 0;
    }
}

@media only screen and (max-width: 700px) {
    .v-card__title {
        justify-content: center;
    }
    .card-product__data {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card-item .card-product {
        flex-direction: row;
    }
}

.card-product__data {
    .order-block__price {
        text-align: start;
    }
}

.fz-12 {
    font-size: 12px;
}

.font-weight-light {
    font-weight: 100;
}

.text--black {
    color: black;
}

.btn-qty {
    min-width: 0 !important;
    width: 30px !important;
    padding: 0 !important;
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.pl-0 {
    padding-left: 0;
}

.widget_title,
.cart-title,
.widget-row-form .title {
    font-family: 'Inter', sans-serif;
    font-weight: normal;
    font-size: 15px;
    color: black;
}

.wrap_select {
    font-size: 13px;
}

@media only screen and (max-width: 910px) {
    .order_details {
        .card-product {
            .card-product__data {
                align-items: flex-start;
                text-align: left;
                margin-left: 10px;
            }
        }
    }

    .order_details  {
        .product-cart {
            .card-item {
                .card-product__data {
                    .card-product__wrap-text {
                        .card-product__title {
                            text-align: left;
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
    }
}

.order_status_message {
    font-size: 14px;
}
</style>
