/**
 * Gets product variations
 * @param variationNames
 * @return {*|*[]}
 */
export function parseVariationIdentifiers(variationNames) {
    let output = [];
    if (!variationNames) {
        return output;
    }
    if (variationNames.indexOf(',') !== -1) {
        return variationNames.split(',');
    } else {
        output = [variationNames];
    }
    return output;
}

/**
 * Checks if all variations are found
 * @param list
 * @param names
 * @return {boolean}
 */
export function hasVariationsInOriginal(list, names) {
    let count = 0;
    names.forEach((name) => {
        if (list.findIndex(fn => fn.value === name) !== -1) {
            count++;
        }
    });
    return names.length === count;
}

export function sumVariations(list, names) {
    let output = 0;
    names.forEach((name) => {
        let item = list.find(fn => fn.value === name);
        if (item) {
            output += parseFloat(item.price);
        }
    });
    return output;
}

export function sumSelectedVariations(selected) {
    return selected.map(item => parseFloat(item.price) || 0).reduce((acc, number) => acc + number, 0)
}
