const token = document.currentScript.getAttribute("data-token");
const baseUrl = document.currentScript.getAttribute("base-url") + "/api/public/";
const tagScript = document.getElementById("script-widget-base-url");

const getHeaders = () => {
    let authToken = localStorage.widgetAuthTokenCustomer;
    let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token,
        mode: "cors",
    };
    if (authToken) {
        headers.Authorization = `Bearer ${authToken}`;
    }
    return headers;
};

const post = async (url = "", data = {}) => {
    let headers = getHeaders();
    const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(data)
    });
    return await response.json();
};

const get = async (url = "") => {
    let headers = getHeaders();
    const response = await fetch(url, {
        method: "GET",
        headers
    });
    return await response.json();
};


export const customerLogin = (email, password) => {
    let baseUrl = tagScript.dataset.widgetBaseUrl
    let url = `${baseUrl}/api/login`
    let data = {
        email: email,
        password: password,
        is_customer_auth: true,
    };
    return post(url, data);
}

export const getCustomerOrders = () => {
    let baseUrl = tagScript.dataset.widgetBaseUrl
    return get(baseUrl + '/api/account/orders')
}

export const getCustomerLastOrder = () => {
    let baseUrl = tagScript.dataset.widgetBaseUrl
    return get(baseUrl + '/api/account/last-order')
}

export const customerLogout = () => {
    let baseUrl = tagScript.dataset.widgetBaseUrl
    return get(baseUrl + '/api/logout')
}

export const customerForgotPassword = (email) => {
    let baseUrl = tagScript.dataset.widgetBaseUrl
    let url = `${baseUrl}/api/forgot-password`
    let data = {
        email: email,
        is_customer: true,
    };
    return post(url, data);
}

export const getWidget = () => {
    return get(baseUrl + "widget");
};

export const getSchedule = day => {
    return get(baseUrl + "schedule/" + day);
};

export const submitOrder = form => {
    return post(baseUrl + "submit-order", form);
};

export const getSettings = () => {
    return get(baseUrl + "settings");
};

export const getProducts = () => {
    return get(baseUrl + "products");
};
