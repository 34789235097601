<template>
    <div class="time">
        <template v-if="dateData">
            <template v-if="dateShedules">
                <template v-if="dateShedulesInDay && dateShedulesInDay.length > 0">
                    <div class="time_title">{{ $t('general.day') }}</div>
                    <div class="time_items" :class="{ 'time_items_vending': isRangeSchedules(dateShedules) }">
                        <label v-for="shedule in dateShedulesInDay" :key="dateData.day + '_' + shedule.id">
                            <input
                                type="radio"
                                v-model="form.schedule_object"
                                :value="shedule"
                                required
                                :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                            />
                            <div
                                :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                :style="{
                                background:
                                    form.schedule_object === shedule
                                        ? widget.style.color
                                        : 'white',
                                color:
                                    form.schedule_object === shedule
                                        ? 'white'
                                        : '#232020'
                            }"
                                class="timepicker-block">
                                {{ getDisplayName(shedule) }}
                            </div>
                        </label>
                    </div>
                </template>
                <template v-if="dateShedulesInEvening && dateShedulesInEvening.length > 0">
                    <div class="time_title">{{ $t('general.evening') }}</div>
                    <div class="time_items">
                        <label v-for="shedule in dateShedulesInEvening" :key="dateData.day + '_' + shedule.id">
                            <input
                                type="radio"
                                v-model="form.schedule_id"
                                :value="shedule.id"
                                required
                                :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                            />
                            <div :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                                 :style="{
                                background:
                                    form.schedule_id === shedule.id
                                        ? widget.style.color
                                        : 'white',
                                color:
                                    form.schedule_id === shedule.id
                                        ? 'white'
                                        : '#232020'
                            }"
                                 class="timepicker-block">
                                {{ getDisplayName(shedule) }}
                            </div>
                        </label>
                    </div>
                </template>
                <div
                    v-if="dateShedulesInDay && dateShedulesInEvening && dateShedulesInDay.length === 0 && dateShedulesInEvening.length === 0"
                    class="time_not_found">
                    {{ $t('general.no-hours-available') }}
                </div>
            </template>
            <div v-else class="time_not_found">
                {{ $t('general.no-hours-available') }}
            </div>
        </template>
        <div v-else class="time_not_found">
            {{ $t('general.the-date-has-not') }}
        </div>
    </div>
</template>
<script>
export default {
    props: ["widget", "form", "schedule", "is_vending"],
    mounted() {
        this.searchFirstSheduleFromFirstDay()
    },
    methods: {
        searchFirstSheduleFromFirstDay: function () {
            if (this.dateShedules && this.dateShedules[0]) {
                this.form.schedule_object = this.dateShedules[0]
            }
        },
        setDateTimeOrder(id, date) {
            this.form.schedule_id = id
            this.form.date = date
        },
        getDisplayName(item) {
            if (this.is_vending) {
                if (item.from_at && item.end_at) {
                    return `${item.from_at}-${item.end_at}`;
                } else if (item.started_at) {
                    return item.started_at;
                }
            }
            return item.started_at;
        },
        isRangeSchedules(schedule) {
            if (this.is_vending) {
                return schedule.some((el) => el.from_at && el.end_at);
            }
            return false;
        },
    },
    watch: {
        'form.schedule_object': function (val) {
            this.form.schedule_id = val.id
        }
    },
    computed: {
        dateData: function () {
            if (this.is_vending) {
                if (this.form.date_object && this.form.date_object.day_vending_shedules[0]) {
                    this.setDateTimeOrder(this.form.date_object.day_vending_shedules[0].id, this.form.date_object.day_vending_shedules[0].day_data)
                    return this.form.date_object
                }
            } else {
                if (this.form.date_object && this.form.date_object.day_shedules[0]) {
                    this.setDateTimeOrder(this.form.date_object.day_shedules[0].id, this.form.date_object.day_shedules[0].day_data)
                    return this.form.date_object
                }
            }
            return ''
        },
        dateShedules: function () {
            if (this.is_vending) {
                if (this.dateData.day_vending_shedules && this.dateData.day_vending_shedules.length > 0) {
                    return this.dateData.day_vending_shedules
                }
            } else {
                if (this.dateData.day_shedules && this.dateData.day_shedules.length > 0) {
                    return this.dateData.day_shedules
                }
            }
            return ''
        },
        dateShedulesInDay: function () {
            if (this.dateShedules) {
                return this.dateShedules.filter(w => w.is_day === true)
            }
            return ''
        },
        dateShedulesInEvening: function () {
            if (this.dateShedules) {
                return this.dateShedules.filter(w => w.is_day === false)
            }
            return ''
        },
    }
};
</script>

<style lang="scss" scoped>
.time {
    display: grid;
    text-align: center;

    &_not_found {
        text-align: center;
        font-style: italic;
        font-size: 14px;
        margin-top: 20px;
    }

    &_items {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 8px 18px;

        label {
            background: #ffffff;
            border: 1px solid #cecece;
            border-radius: 5px;
            cursor: pointer;

            div {
                border-radius: 5px;
                min-height: 35px;
                display: grid;
                place-items: center;
            }
        }

        input[type="radio"] {
            display: none;
        }

        input[type="radio"]:checked + div {
            background-color: rgb(128, 128, 128);
        }

        &_vending {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .time_title {
        margin: 15px 0 15px 0;
        color: #232020;
    }
}
</style>
