<template>
    <input
        class="input"
        :value="value"
        @input="$emit('input', $event.target.value)"
    />
</template>

<script>
export default {
    props: ["value"]
};
</script>
<style scoped>
.input {
    border: 1px solid #cecece;
    border-radius: 5px;
    padding: 5px 0px 5px 14px;
    width: 100%;
    font-size: 16px;
    box-sizing: border-box;
}
</style>
