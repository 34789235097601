<template>
    <div>
        <h3 class="widget_title">{{ widget.title }}</h3>
        <form class="widget_form" id="widget_form" @submit.prevent="$emit('submit')">
            <div class="widget-row-form">
                <custom-input
                    v-model="form.name"
                    type="text"
                    :placeholder="$t('general.name')"
                    required
                    :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                />
                <div
                    v-if="form.errors.name && form.errors.name.length > 0"
                    class="widget_form__error"
                >
                    {{ form.errors.name[0] }}
                </div>
            </div>
            <div class="widget-row-form">
                <custom-input
                    v-model="form.phone"
                    type="text"
                    :placeholder="$t('general.phone')"
                    required
                    :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                />
                <div
                    v-if="form.errors.phone && form.errors.phone.length > 0"
                    class="widget_form__error"
                >
                    {{ form.errors.phone[0] }}
                </div>
            </div>
            <div class="widget-row-form">
                <custom-input
                    v-model="form.email"
                    type="text"
                    :placeholder="$t('general.email')"
                    required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                />
                <div
                    v-if="form.errors.email && form.errors.email.length > 0"
                    class="widget_form__error"
                >
                    {{ form.errors.email[0] }}
                </div>
                <div v-if="!widget.is_auth" class="my-3 subscription__wrap">
                    <input type="checkbox" id="subscription" v-model="form.create_customer"
                           :color="`${widget.style.color}`"
                           class="subscription__input">
                    <label for="subscription" class="subscription__label">{{ $t('general.save-an-account') }}</label>
                </div>
            </div>

            <div class="widget-row-form">
                <textarea
                    v-model="form.details"
                    class="widget_form__details"
                    rows="4"
                    :placeholder="$t('general.order-details')"
                    :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                ></textarea>
                <div
                    v-if="form.errors.details && form.errors.details.length > 0"
                    class="widget_form__error"
                >
                    {{ form.errors.details[0] }}
                </div>
            </div>
            <!-- default delivery block (if delivery enabled) -->
            <div class="widget-row-form" v-if="deliveryEnabled">
                <div class="delivery-buttons delivery-buttons_negative"
                     :class="widget.style.roundedCorners ? '' : 'border-radius-0'">
                    <button type="button"
                            @click="deliveryButton"
                            class="delivery-buttons__item delivery-buttons__delivery_btn"
                            :class="{active: isDelivery}"
                            :style="isDelivery ? `background: ${widget.style.color}` : `border: 1px solid ${widget.style.color}; color: ${widget.style.color}`"
                            v-if="!isOnlyPickup">
                        {{ $t('general.delivery') }}
                    </button>
                    <button type="button"
                            class="delivery-buttons__item delivery-buttons__pickup_btn"
                            :class="{active: isPickUp}"
                            :style="isPickUp ? `background: ${widget.style.color}` : `border: 1px solid ${widget.style.color}; color: ${widget.style.color}`"
                            @click="pickUpButton">
                        {{ $t('general.pick-up') }}
                    </button>
                    <button type="button"
                            v-if="vendingEnabled"
                            class="delivery-buttons__item delivery-buttons__vending_btn"
                            :class="{active: isVending}"
                            :style="isVending ? `background: ${widget.style.color}` : `border: 1px solid ${widget.style.color}; color: ${widget.style.color}`"
                            @click="vendingButton"
                    >
                        {{ $t('general.machine') }}
                    </button>
                </div>
                <div class="pickup-block" v-if="isPickUp">
                    <div class="widget-row-form" v-if="isDisplayPayment('pickup')">
                        <div class="title">{{ $t('general.payment_methods') }}</div>
                        <div class="payment-methods__wrap">
                            <div class="payment-methods__item" v-if="widget.settings.pickup_cash_enabled">
                                <input type="radio" id="payment_method_cash" name="payment_method" value="cash"
                                       class="payment-methods__radio" v-model="form.payment_method" required
                                       checked
                                       :style="{color: widget.style.color}">
                                <label for="payment_method_cash">{{ $t('general.payment_method_cash') }}</label>
                            </div>
                            <div class="payment-methods__item" v-if="widget.settings.pickup_mollie_enabled">
                                <input type="radio" id="payment_method_mollie" name="payment_method" value="mollie"
                                       class="payment-methods__radio" v-model="form.payment_method" required
                                       :style="{color: widget.style.color}">
                                <label for="payment_method_mollie">{{ $t('general.payment_method_card') }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="delivery-block" v-if="isDelivery">
                    <div class="widget-row-form">
                        <input
                            class="input"
                            v-model="form.zipcode"
                            maxlength="20"
                            :placeholder="$t('general.zipcode')"
                            type="text"
                            required
                            @input="deliveryPrice"
                            :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                        />
                        <div
                            v-if="showDontDelivery"
                            class="widget_form__error">
                            {{ $t('general.sorry-we-dont-delivery') }}
                        </div>
                        <div
                            v-if="form.errors.zipcode && form.errors.zipcode.length > 0"
                            class="widget_form__error"
                        >
                            {{ form.errors.zipcode[0] }}
                        </div>
                    </div>
                    <div class="widget-row-form">
                        <textarea
                            v-model="form.address"
                            class="widget_form__details"
                            rows="3"
                            :placeholder="$t('general.address')"
                            :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                        ></textarea>
                        <div
                            v-if="form.errors.address && form.errors.address.length > 0"
                            class="widget_form__error"
                        >
                            {{ form.errors.address[0] }}
                        </div>
                    </div>
                    <div class="widget-row-form" v-if="isVatShown">
                        <input
                            class="input"
                            v-model="form.vat_number"
                            maxlength="20"
                            :placeholder="$t('general.vat-number')"
                            type="text"
                            :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                        />
                        <div
                            v-if="form.errors.vat_number && form.errors.vat_number.length > 0"
                            class="widget_form__error"
                        >
                            {{ form.errors.vat_number[0] }}
                        </div>
                    </div>
                    <div class="widget-row-form">
                        <textarea
                            v-model="form.info"
                            class="widget_form__details"
                            rows="3"
                            :placeholder="$t('general.info')"
                            :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                        ></textarea>
                    </div>
                    <div class="widget-row-form" v-if="isDisplayPayment('delivery')">
                        <div class="title">{{ $t('general.payment_methods') }}</div>
                        <div class="payment-methods__wrap">
                            <div class="payment-methods__item" v-if="widget.settings.delivery_cash_enabled">
                                <input type="radio" id="payment_method_cash_" name="payment_method" value="cash"
                                       class="payment-methods__radio" v-model="form.payment_method" required
                                       checked
                                       :style="{color: widget.style.color}" :color="widget.style.color">
                                <label for="payment_method_cash_">{{ $t('general.payment_method_cash') }}</label>
                            </div>
                            <div class="payment-methods__item" v-if="widget.settings.delivery_mollie_enabled">
                                <input type="radio" id="payment_method_mollie_" name="payment_method" value="mollie"
                                       class="payment-methods__radio" v-model="form.payment_method" required
                                       :style="{color: widget.style.color}" :color="widget.style.color">
                                <label for="payment_method_mollie_">{{ $t('general.payment_method_card') }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- vending delivery block (if enabled only vending) -->
            <div class="widget-row-form widget-row-form_negative widget-row-form_flex"
                 :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                 v-if="vendingEnabled && !deliveryEnabled">
                <button type="button"
                        class="delivery-buttons__vending_btn delivery-buttons__vending_btn_offset"
                        :class="{active: isVending}"
                        :style="isVending ? `background: ${widget.style.color}` : `border: 1px solid ${widget.style.color}; color: ${widget.style.color}`"
                        @click="vendingButton"
                >
                    {{ $t('general.machine') }}
                </button>
                <button type="button"
                        :class="{active: !isVending}"
                        class="delivery-buttons__vending_btn delivery-buttons__vending_btn_offset"
                        @click="$emit('cancel-vending')"
                        :style="!isVending ? `background: ${widget.style.color}` : `border: 1px solid ${widget.style.color}; color: ${widget.style.color}`"
                >
                    {{ $t('general.store') }}
                </button>
            </div>
            <div class="widget-row-form">
                <DatePicker
                    v-if="widget"
                    :widget="widget"
                    :form="form"
                    @change-date="$emit('change-date')"
                />
                <div
                    v-if="form.errors.date && form.errors.date.length > 0"
                    class="widget_form__error"
                >
                    {{ form.errors.date[0] }}
                </div>
            </div>
            <div class="widget-row-form">
                <TimePicker
                    v-if="widget"
                    :widget="widget"
                    :form="form"
                    :schedule="schedule"
                    :is_vending="isVending"
                    :processTime="processTime"
                />
                <div
                    v-if="form.errors.time && form.errors.time.length > 0"
                    class="widget_form__error"
                >
                    {{ form.errors.time[0] }}
                </div>

                <div class="card-product__description" style="margin: 20px 0; word-wrap: break-word" v-if="isDelivery">
                    {{ deliveryDesc }}
                </div>
            </div>

            <div class="desktop-hide">
                <div class="widget_buttons">
                    <button
                        v-if="blocked_btn"
                        type="button"
                        class="widget_btn btn_submit"
                        :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                        :style="{ background: '#777', color: '#fff' }">
                    <span class="spinner-icon">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 4335 4335"
                             width="100" height="100"><path fill="#ffffff"
                                                            d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z"
                        /></svg></span>
                    </button>
                    <button
                        v-else
                        type="submit"
                        class="widget_btn"
                        form="widget_form"
                        :style="{ background: widget.style.color, color: '#fff' }"
                        :class="widget.style.roundedCorners ? '' : 'border-radius-0'">
                        <span>{{ $t('general.submit-order') }}</span>
                    </button>
                </div>
            </div>

            <div class="widget_buttons" v-if="String(shop_mode) === '2'">
                <button
                    type="button"
                    @click="clear"
                    class="widget_btn"
                    :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                >
                    {{ $t('general.clear') }}
                </button>
                <button
                    v-if="blocked_btn"
                    type="button"
                    class="widget_btn btn_submit"
                    :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                    :style="{ background: '#777', color: '#fff' }">
                    <span class="spinner-icon">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 4335 4335"
                             width="100" height="100"><path fill="#ffffff"
                                                            d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z"
                        /></svg></span>
                </button>
                <button
                    v-else
                    type="submit"
                    class="widget_btn"
                    :class="widget.style.roundedCorners ? '' : 'border-radius-0'"
                    :style="{ background: widget.style.color, color: '#fff' }">
                    <span>{{ $t('general.submit') }}</span>
                </button>
                <div class="order_status_message">{{ order_status_message }}</div>
            </div>
        </form>
    </div>
</template>

<script>
import DatePicker from "./DatePicker.vue";
import TimePicker from "./TimePicker.vue";
import CustomInput from "./CustomInput.vue";

export default {
    components: {DatePicker, TimePicker, CustomInput},
    props: ["widget", "form", "blocked_btn", "shop_mode", "deliveryCost", "isPickUp", "isDelivery", "isVending", "deliveryPrice", "vendingButton", "pickUpButton", "deliveryButton", "deliveryEnabled", "vendingEnabled", "deliveryDesc", "schedule", "processTime", "isVatShown", "order_status_message"],
    data: () => ({
        payment_methods: ['cash', 'mollie'],
    }),
    computed: {
        showDontDelivery() {
            if (this.deliveryCost === null && this.form.zipcode && this.form.zipcode.length > 3) {
                return true;
            }
            return false;
        },
        isOnlyPickup() {
            if (this.widget.settings.is_only_pickup && !this.widget.settings.delivery_enabled) {
                // if is enabled pickup only
                return true
            }
            return false;
        },
    },
    methods: {
        clear() {
            this.form.name = ""
            this.form.email = ""
            this.form.phone = ""
            this.form.details = ""
            this.form.zipcode = ""
            this.form.address = ""
            this.form.vat_number = ""
            this.form.info = ""
            this.form.date = ""
            this.form.date_object = ""
            this.form.schedule_id = ""
            this.form.schedule_object = ""
            this.form.payment_method = "";
            this.form.create_customer = false;
            this.form.is_vending = false;
            this.form.errors = {}
        },
        isDisplayPayment(type) {
            let result = false;
            this.payment_methods.forEach((name) => {
                if (this.widget.settings[`${type}_${name}_enabled`]) {
                    result = true;
                }
            });
            return result;
        },
    },
};
</script>

<style lang="scss" scoped>
.payment-methods__item {
    display: flex;
    justify-content: start;
    align-items: center;
}

.delivery-buttons {
    margin-bottom: 7px;
    display: flex;
    justify-content: space-between;

    &_negative {
        margin-left: -4px;
        margin-right: -4px;
    }

    @media (max-width: 380px) {
        flex-wrap: wrap;
    }
}

.delivery-buttons__pickup_btn {
    border-radius: 10px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    border: none;
    font-size: 16px;
    width: calc(50% - 11px);
    white-space: nowrap;
    height: 37px;
    box-sizing: border-box;
    text-align: center;
}

.delivery-buttons__delivery_btn {
    border-radius: 10px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    border: none;
    font-size: 16px;
    width: calc(50% - 11px);
    height: 37px;
    box-sizing: border-box;
    text-align: center;
}

.delivery-buttons__vending_btn {
    border-radius: 10px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    padding: 0 5px;
    white-space: nowrap;
    flex: 1;
    height: 37px;
    box-sizing: border-box;
    text-align: center;
    &_offset {
        margin: 0 4px 8px;
    }
}

.delivery-buttons__item {
    margin: 0 4px 8px;
    @media (max-width: 390px) {
        flex: 1;
        padding: 0 5px;
    }
}

.active {
    background: #fff;
    cursor: default;
}

.input {
    border: 1px solid #cecece;
    border-radius: 5px;
    padding: 5px 0 5px 14px;
    width: 100%;
    font-size: 16px;
    box-sizing: border-box;
}

.title {
    font-family: 'Inter', sans-serif;
    line-height: 17px;
    color: #232020;
    margin-bottom: 17px;
    font-size: 1.25rem !important;
    font-weight: 500;
    letter-spacing: .0125em !important;
}

.payment-methods {
    &__wrap {
        display: flex;
    }

    &__item {
        margin-right: 8px;
    }

    &__radio {
        margin-right: 3px;
    }
}

@media only screen and (max-width: 910px) {
    .delivery-buttons__pickup_btn {
        font-weight: 100;
    }

    .delivery-buttons__delivery_btn {
        font-weight: 100;
    }

    .delivery-buttons__vending_btn {
        font-weight: 100;
    }
}

.border-radius-0 {
    border-radius: 0 !important;

    button {
        border-radius: 0 !important;
    }
}

.order_status_message {
    font-size: 14px;
}

.subscription__wrap {
    display: flex;
    margin: 10px 0;

    .subscription__input {
        border: 1px solid #cecece;
        border-radius: 5px;
        padding: 5px;
        font-size: 16px;
        box-sizing: border-box;
    }

    .subscription__label {
        margin-left: 5px;
        font-size: 14px;
    }
}
</style>
