<template>
    <div class="picker">
        <v-row class="mt-4">
            <v-col cols="2">
                <label class="label">
                    <input
                        type="radio"
                        value="#1E5CBA"
                        v-model="widget.style.color"
                    />
                    <div :style="{ background: '#1E5CBA' }"></div>
                </label>
            </v-col>
            <v-col cols="2">
                <label class="label">
                    <input
                        type="radio"
                        value="#88BA1E"
                        v-model="widget.style.color"
                    />
                    <div :style="{ background: '#88BA1E' }"></div>
                </label>
            </v-col>
            <v-col cols="2">
                <label class="label">
                    <input
                        type="radio"
                        value="#BA1EB4"
                        v-model="widget.style.color"
                    />
                    <div :style="{ background: '#BA1EB4' }"></div>
                </label>
            </v-col>
            <v-col cols="2">
                <label class="label">
                    <input
                        type="radio"
                        value="#BA7B1E"
                        v-model="widget.style.color"
                    />
                    <div :style="{ background: '#BA7B1E' }"></div>
                </label>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-color-picker
                    class="mt-4"
                    v-model="widget.style.color"
                    :mode="'hexa'"
                    label="Text color"
                ></v-color-picker>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    props: ["widget"],
};
</script>

<style lang="scss" scoped>
.label {
    cursor: pointer;

    input[type="radio"] {
        display: none;
    }

    div {
        width: 37px;
        height: 37px;
        border: 1px solid #cecece;
        border-radius: 5px;
    }
}

.max-width-300 {
    max-width: 300px;
}
</style>
